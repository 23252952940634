
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const select = ref('1')

    return {
      select
    }
  }
})
